import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['field', 'cards']

  click(event){
    let card_id = event.currentTarget.getAttribute("data-card-id");
    this.fieldTarget.value = card_id;
    this.cardsTargets.forEach(card => {
      if(card == event.currentTarget){
        card.classList.add("selected-card")
      }else{
        card.classList.remove("selected-card")
      }
    })
  }
}
