// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

const channels = require.context('.', true, /_channel\.js$/)
channels.keys().forEach(channels)


window.dbgCheckAllRadioButtons = function () {
  $( "input[value='4']" ).each(function( index ) {
    $(this)[0].checked = true;
  });
};
