import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {clickRecorderUrl: String}
  connect(){
    var theThis = this;
    document.addEventListener("click", function(event){
      if(theThis.element.contains(event.target)){
        if(!$(theThis.element).hasClass("opened") && $(theThis.element).children(".item").length > 1){
          $.ajax(theThis.clickRecorderUrlValue, {})
        }
        $(theThis.element).toggleClass("opened")
        let acceptButton = document.getElementById("accept-button");
        if(acceptButton){
          acceptButton.classList.remove("disabled");
        }
      }else{
        $(theThis.element).removeClass("opened")
      }
    })
  }
}
