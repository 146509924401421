import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {updateUrl: String}

  onclick(){
    if(this.element.parentNode.id == "basestack"){
      let previousIndex = $(this.element).index(".item");
      this.element.parentNode.append(this.element)
      let numberOfChildren = $(this.element.parentNode).children(".item").length
      if(numberOfChildren > 1 && previousIndex < numberOfChildren - 1){
        $.ajax(this.updateUrlValue, {});
      }
    }
    if(Date.now() - this.lastClicked < 1000){
      $("#largeImage")[0].src = $(this.element)[0].children[0].src;
      $("#largeImageContainer")[0].style.display = "block";
    }
    this.lastClicked = Date.now();
  }
}
